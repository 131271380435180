<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Foto Conteúdo - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn v-if="contentphoto" color="blue-grey" class="black--text" :to="{name: 'contentphotos', params: {content_id: contentphoto.content_id}}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-content-photo :contentphoto="contentphoto" :update="true"></form-content-photo>
</span>
</template>

<script>
import FormContentPhotoComponent from "./partial/FormContentPhotoComponent";

export default {
    name: "EditContentPhotoComponent",
    created() {
        this.loadContentPhoto()
    },
    props: {
        cont_ft_id: {
            require: true
        }
    },
    data() {
        return {
            contentphoto: {
                cont_ft_id: '',
                content_id: '',
                cont_ft_titulo: '',
                cont_ft_legenda: '',
                cont_ft_fotografo: '',
                cont_ft_icone: '',
                cont_ft_foto: '',
                cont_ft_link: '',
                cont_ft_codigo: '',
                cont_ft_capa: '',
                cont_ft_status: false,
            },
        }
    },
    methods: {
        loadContentPhoto() {
            this.$store.dispatch('loadContentPhoto', this.cont_ft_id)
                .then(response => {
                    this.contentphoto = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },

    },
    components: {
        formContentPhoto: FormContentPhotoComponent
    }
};
</script>

<style scoped>

</style>
